import React from "react";
import { Container } from "../Styled/Container";
import { Image } from "../Styled/Image";
import SectionConfirmation from "../components/SectionConfirmation";
import { Section } from "../Styled/Section";
import SectionProgramme from "../components/SectionProgramme";
import SectionLogement from "../components/SectionLogement";

export default function Home() {
  return (
    <div>
      <Container>
        <Image src="image/heroImg.jpg" alt="" />
        <Section>
          <SectionConfirmation />
          <SectionProgramme />
          <SectionLogement />
        </Section>
        
       
      </Container>
    </div>
  );
}
