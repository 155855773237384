import styled from "styled-components";

export const Enluminure = styled.img`
  display: block;
  width: 180px;
  margin: 0 auto -80px auto;
`;

export const MainText = styled.h1`
  font-family: livory;
  font-size: 32px;
  line-height: 45px;
  letter-spacing: 0.05ch;
  margin: 110px 8vw 180px 8vw;
`;

export const CallToAction = styled.button`
  width: 352px;
  height: 72px;
  display: block;
  margin: 32px auto 192px auto;
  background-color: transparent;
  border: solid 2px #cb7a6f;
  font-family: gill-sans-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  color: #cb7a6f;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: #cb7a6f;
    color: white;
  }
`;
