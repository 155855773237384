import React from "react";
import { StyledIframe } from "../Styled/StyledIframe";

const Iframe = ({ source }) => {
  if (!source) {
    return <div>Loading...</div>;
  }

  const src = source;
  return (
    <div>
      <StyledIframe src={src} title="RSVP form" />
    </div>
  );
};

export default Iframe;
