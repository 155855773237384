import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 10vw auto 10vw;
  grid-template-rows: auto auto auto;

  /* On screens that are 600px or less, set the background color to blue */
  @media screen and (max-width: 1100px) {
    display: grid;
    grid-template-columns: 0vw auto 0vw;
    grid-template-rows: auto auto auto;
  }
`;
