import styled from "styled-components";

export const Background = styled.div`

  width: 100% ;
  padding: 32px 0 200px 0  ;
  background-color: white;
`;

export const ProgrammeTitle = styled.h1`
    font-family: livory;
    font-size: 48px;
    line-height: 45px;
    letter-spacing: 0.05ch;
    color: #D83F1A;
    margin: 0 8vw 96px 8vw;
`

export const MainText = styled.h1`
  font-family: livory;
  font-style: italic ;
  font-size: 32px;
  line-height: 45px;
  letter-spacing: 0.05ch;
  margin: 110px 8vw 48px 8vw;
`;

export const Location = styled.h3`
  font-family: livory;
  font-size: 24px;
  line-height: 25px;
  font-weight:700 ;
  letter-spacing: 0.05ch;
  color: #D83F1A;
  text-align: center ;
  margin: 56px 0 0  0 ;
`

export const Details = styled.h3`
  font-family: livory;
  font-size: 24px;
  font-style: italic ;
  line-height: 25px;
  opacity: 0.8;
  letter-spacing: 0.05ch;
  color: #D83F1A;
  margin: 8px 0 0 0 ;
  text-align: center ;
`

export const Notabene = styled.p`
    font-family: livory;
    font-size: 20px;
    font-style: italic ;
    line-height: 21px;
    letter-spacing: 0.05ch;
    color: #70655F;
    margin: 96px 18vw 0 18vw ;
    text-align: center ;
`

export const CallToAction = styled.button`
  padding: 0 32px 0 32px  ;
  height: 72px;
  display: block;
  margin: 32px auto 192px auto;
  background-color: transparent;
  border: solid 2px #70655F;
  font-family: gill-sans-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  color: #70655F;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: #70655F;
    color: white;
  }
`