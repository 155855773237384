import styled from "styled-components";

export const Section = styled.section`
  grid-column-start: 2;
  margin : 232px 0 200px 0;
  background-color: #fdfbf9;
  padding: 96px 0 0px 0;
  text-align: center;
  z-index: 1000;

  /* On screens that are 600px or less, set the background color to blue */
  @media screen and (max-width: 1100px) {
    padding: 96px 0 0px 0;
  }
`;
