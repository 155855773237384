import React from "react";
import { BackgroundGreen, CallToAction, Frise, MainText, Photo } from "../Styled/StyledLogement";
import { StyledLink } from "../Styled/StyledNavbar";
import { Background } from "../Styled/StyledProgramme";


export default function SectionLogement() {
  return (
    <div>
        <BackgroundGreen>
            <Photo src="image/Photo.png" alt="Photo d'un gite et piscine" ></Photo>
            <MainText>  
                        Pour vous aider à préparer votre séjour, 
                        nous avons repéré quelques logements 
                        à proximité du lieu de réception.<br/>
            </MainText>
            <StyledLink to={{ pathname:"https://goo.gl/maps/5xczzAno5Z18dsNA6"}} target="_blank">
            <CallToAction>VOIR LA LISTE DES LIEUX</CallToAction>
            </StyledLink>
        </BackgroundGreen>
        <Background>
      <Frise></Frise>
      <br/>
      </Background>
    </div>
  );
}
