import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
 *{
     box-sizing: border-box;
     margin : 0;
     color : #706660;
     font-family: Livory;
     font-weight: 300;
     text-rendering: optimizeLegibility;
     letter-spacing: 0.30px;
     transition: 200ms;
 
 }

 span{
    font-family: garamond-premier-pro, serif;
    font-weight: 400;
    font-style: italic;
 }

 em{
    font-family: livory, serif;
    font-weight: 500;
    font-style: italic;
    font-size: 24px;
    
 }

 h1{
    font-family: livory, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 32px;
    
    
 }

`;
