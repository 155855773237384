import React from "react";
import { StyledLink } from "../Styled/StyledNavbar";
import { Background, ProgrammeTitle, MainText, Location, Details, Notabene, CallToAction } from "../Styled/StyledProgramme";

export default function SectionProgramme() {
  return (
    <div>
    <Background>
        <MainText>Le programme du week-end</MainText>
        <ProgrammeTitle>LE SAMEDI <br/>16 JUILLET 2022</ProgrammeTitle>
        <ProgrammeTitle></ProgrammeTitle>
        <Location>14h - Cérémonie civile</Location>
        <Details>Parc et Château de Malpagat <br/>31240 L'Union</Details>
        <Location>15h - Cérémonie religieuse</Location>
        <Details>Église Saint Jean-Baptiste<br/>31240 L'Union</Details>
        <Location>17h30 - Réception</Location>
        <Details>Manoir du Thouron<br/>81370 Saint-Sulpice-la-Pointe</Details>
        <Location>Lendemain - Brunch</Location>
        <Details>Manoir du Thouron<br/>81370 Saint-Sulpice-la-Pointe</Details>
        <Notabene>NB : afin de relier les différents lieux de cérémonie et de réception, 
un véhicule est nécessaire. Faite nous signe si besoin d'organiser du covoiturage !</Notabene>
        <StyledLink to={{ pathname:"https://www.google.com/maps/d/u/0/viewer?mid=1TqNvvXxQdzww11YW_kvGpjbg64IjhJBD&ll=43.68841389084308%2C1.501488853100601&z=12"}} target="_blank">
        <CallToAction >VOIR LES ITINÉRAIRES</CallToAction>
        </StyledLink>
     </Background>
    </div>
  );
}
