import React from "react";

import { StyledNavbar } from "../Styled/StyledNavbar";
import { StyledLink } from "../Styled/StyledNavbar";

export default function Navbar() {
  return (
    <StyledNavbar>
      <StyledLink to="/">
        <h1>CORALIE & VICTOR</h1>
        <em>Samedi 16 juillet 2022</em>
      </StyledLink>
    </StyledNavbar>
  );
}
