import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledNavbar = styled.nav`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 100%;
  background-color: white;
  position: sticky;
  top: 0px;
  padding: 80px 0 16px 0;
  z-index: 5000;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
