import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import RSVP from "./pages/RSVP";
import Navbar from "./components/Navbar";
import Global from "./Styled/Global";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Global />
      <Switch>
        <Route path="/RSVP" component={RSVP} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
