import React from "react";
import { Container } from "../Styled/Container";
import Iframe from "../components/Iframe";
import { SectionRSVP } from "../Styled/SectionRsvp";

export default function RSVP() {
  return (
    <div>
      <Container>
        <SectionRSVP>
          <Iframe source="https://airtable.com/embed/shrE0BJKH6ImKDFuV?" />
        </SectionRSVP>
      </Container>
    </div>
  );
}
