import React from "react";
import {
  CallToAction,
  Enluminure,
  MainText,
} from "../Styled/StyledConfirmation";
import { StyledLink } from "../Styled/StyledNavbar";

export default function SectionConfirmation() {
  return (
    <div>
      <Enluminure src="image/Enlum.png" alt="Symbole florale d'enluminure" />
      <MainText>
        Nous avons le plaisir de vous convier à notre mariage qui sera célébré
        sous le soleil toulousain le 16 juillet 2022. Le lendemain midi, nous
        serions ravis de prolonger la fête avec vous pour un brunch.
      </MainText>
      <em>Nous espérons vous retrouver à nos côtés ! </em>
      <StyledLink to="/rsvp">
        <CallToAction>CONFIRMER MA PRÉSENCE</CallToAction>
      </StyledLink>
    </div>
  );
}
