import styled from "styled-components";

export const StyledIframe = styled.iframe`
  background: transparent;
  border: none;
  width: 100%;
  height: auto;
  min-height: 85vh;
  margin: 80px 0 0 0;
`;
