import styled from "styled-components";

export const Image = styled.img`
  grid-column-start: 2;
  margin: 120px auto 0 auto;
  width: 70%;
  max-width: 60vh;
  position: sticky;
  top: 300px;

  @media screen and (max-width: 1100px) {
    grid-column-start: 2;
    width: 90%;
    max-width: 70vh;
  }
`;
