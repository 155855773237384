import styled from "styled-components";

export const BackgroundGreen = styled.div`
background-color: #B3C9BC ;
padding: 0 0 96px 0 ;
width: 100%;
`

export const Photo = styled.img`
margin: -200px auto 0 auto ;
width: 50vw ;

@media screen and (max-width: 1100px) {
  width: 80vw ;
  }
`

export const MainText = styled.h2`
    font-family: livory;
    font-size: 32px;
    font-style: italic ;
    line-height: 33px;
    color:#315637 ;
    letter-spacing: 0.05ch;
    margin: 64px 25% 64px 25%  ;

    @media screen and (max-width: 1100px) {
      margin: 64px 16px 64px 16px  ;
  }
`

export const CallToAction = styled.button`
  width: 352px;
  height: 72px;
  display: block;
  margin: 32px auto 192px auto;
  background-color: transparent;
  border: solid 2px #315637;
  font-family: gill-sans-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  color: #315637;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: #315637;
    color: white;
  }
`

export const Frise = styled.div`
margin: 0 auto 0 auto ;
height: 60px;
background-image: url('image/Frise_rep.png');
background-repeat: repeat-x;
`
